/* eslint-disable no-debugger */
<template>
  <div v-show="headShow()" class="iq-top-navbar shadow-lg">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-navbar-logo d-flex justify-content-between">
          <router-link :to="{ name: 'social.list' }">
            <img :src="image" class="img-fluid" alt="" />
            <!-- <span>私域</span> -->
          </router-link>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu" @click="sidebarmini">
              <div class="main-circle">
                <i class="ri-menu-line"></i>
              </div>
            </div>
          </div>
          <div class="align-self-center">
            <select class="form-select form-select-sm mt-1" v-model="$i18n.locale" @change="setLocale">
              <option disabled="">多國語系</option>
              <option value="zh-TW">中文</option>
              <option value="en-US">ENGLISH</option>
            </select>
            <!-- {{ $route.path }} -->
          </div>
        </div>
        <!-- 右上角 漢堡 mobile-->
        <div class="d-flex align-items-center">
          <nav class="iq-float-menu mobile-set-darklight" style="position: unset;margin-right: 10px;">
            <input type="checkbox" class="iq-float-menu-open" name="menu-open" id="menu-open" />
            <label class="iq-float-menu-open-button" for="menu-open">
              <span class="lines line-1"></span>
              <span class="lines line-2"></span>
              <span class="lines line-3"></span>
            </label>
            <!-- <button class="iq-float-menu-item bg-info" @click="rtlChange(!rtlMode)" title="Direction Mode" data-mode="rtl">
              <i :class="rtlMode ? 'ri-text-direction-l' : 'ri-text-direction-r'"></i>
            </button> -->
            <button class="iq-float-menu-item bg-danger" @click="darkChange(!darkMode)" title="Color Mode" id="dark-mode"
              data-active="true">
              <i :class="darkMode ? 'ri-moon-clear-line' : 'ri-sun-line'" class="d-flex justify-content-center align-items-center"></i>
            </button>
            <!-- <button class="iq-float-menu-item bg-warning" @changeColor title="Comming Soon">
              <i class="ri-palette-line"></i>
            </button> -->
          </nav>
          <div class="mobile-search">
            <router-link :to="{ name: 'social.search' }">
              <button type="button" class="btn btn-circle-sm mb-1 me-1">
                <i class="fas fa-search fa-fw me-0 text-primary"></i>
              </button>
            </router-link>
          </div>
        </div>
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
          <i class="ri-menu-3-line"></i>
        </button> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <nav class="iq-float-menu" style="position: unset;margin-right: 10px;">
            <input type="checkbox" class="iq-float-menu-open" name="menu-open-1" id="menu-open-1" />
            <label class="iq-float-menu-open-button" for="menu-open-1">
              <span class="lines line-1"></span>
              <span class="lines line-2"></span>
              <span class="lines line-3"></span>
            </label>
            <!-- <button class="iq-float-menu-item bg-info" @click="rtlChange(!rtlMode)" title="Direction Mode" data-mode="rtl">
              <i :class="rtlMode ? 'ri-text-direction-l' : 'ri-text-direction-r'"></i>
            </button> -->
            <button class="iq-float-menu-item bg-danger" @click="darkChange(!darkMode)" title="Color Mode" id="dark-mode"
              data-active="true">
              <i :class="darkMode ? 'ri-moon-clear-line' : 'ri-sun-line'" class="d-flex justify-content-center align-items-center"></i>
            </button>
            <!-- <button class="iq-float-menu-item bg-warning" @changeColor title="Comming Soon">
              <i class="ri-palette-line"></i>
            </button> -->
          </nav>
          <!-- 右上角 漢堡 -->
          <router-link :to="{ name: 'social.search' }">
            <button type="button" class="btn btn-circle-sm mb-1 me-1">
              <i class="fas fa-search fa-fw me-0 text-primary"></i>
            </button>
          </router-link>
          <ul class="navbar-nav ms-auto navbar-list">

            <!-- <li>
              <router-link :to="{ name: 'social.list' }" class="d-flex align-items-center">
                <i class="ri-home-line"></i>
              </router-link>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a
                href="#"
                class="dropdown-toggle"
                id="group-drop"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="ri-group-line"></i
              ></a>
              <div
                class="sub-drop sub-drop-large dropdown-menu"
                aria-labelledby="group-drop"
              >
                <div class="card shadow-none m-0">
                  <div
                    class="card-header d-flex justify-content-between bg-primary"
                  >
                    <div class="header-title">
                      <h5 class="mb-0 text-white">Friend Request</h5>
                    </div>
                    <small class="badge bg-light text-dark">4</small>
                  </div>
                  <div class="card-body p-0">
                    <div class="iq-friend-request">
                      <div
                        class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            class="avatar-40 rounded"
                            src="@/assets/images/user/01.jpg"
                            alt=""
                          />
                          <div class="ms-3">
                            <h6 class="mb-0">Jaques Amole</h6>
                            <p class="mb-0">40 friends</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-primary rounded"
                            >Confirm</a
                          >
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-secondary rounded"
                            >Delete Request</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="iq-friend-request">
                      <div
                        class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            class="avatar-40 rounded"
                            src="@/assets/images/user/02.jpg"
                            alt=""
                          />
                          <div class="ms-3">
                            <h6 class="mb-0">Lucy Tania</h6>
                            <p class="mb-0">12 friends</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-primary rounded"
                            >Confirm</a
                          >
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-secondary rounded"
                            >Delete Request</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="iq-friend-request">
                      <div
                        class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            class="avatar-40 rounded"
                            src="@/assets/images/user/03.jpg"
                            alt=""
                          />
                          <div class="ms-3">
                            <h6 class="mb-0">Manny Petty</h6>
                            <p class="mb-0">3 friends</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-primary rounded"
                            >Confirm</a
                          >
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-secondary rounded"
                            >Delete Request</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="iq-friend-request">
                      <div
                        class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between"
                      >
                        <div class="d-flex align-items-center">
                          <img
                            class="avatar-40 rounded"
                            src="@/assets/images/user/04.jpg"
                            alt=""
                          />
                          <div class="ms-3">
                            <h6 class="mb-0">Marsha Mello</h6>
                            <p class="mb-0">15 friends</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-primary rounded"
                            >Confirm</a
                          >
                          <a
                            href="javascript:void();"
                            class="me-3 btn btn-secondary rounded"
                            >Delete Request</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <a href="#" class="btn text-primary">View More Request</a>
                    </div>
                  </div>
                </div>
              </div>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a href="#" class="search-toggle dropdown-toggle" id="notification-drop" data-bs-toggle="dropdown">
                <i class="ri-notification-4-line"></i>
              </a>
              <div class="sub-drop dropdown-menu" aria-labelledby="notification-drop">
                <div class="card shadow-none m-0">
                  <div class="card-header d-flex justify-content-between bg-primary">
                    <div class="header-title bg-primary">
                      <h5 class="mb-0 text-white">新通知</h5>
                    </div>
                    <small class="badge bg-light text-dark">4</small>
                  </div>
                  <div class="card-body p-0">
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/01.jpg" alt="" />
                        </div>
                        <div class="ms-3 w-100">
                          <h6 class="mb-0">Emma Watson Bni</h6>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">95 MB</p>
                            <small class="float-right font-size-12">Just Now</small>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/02.jpg" alt="" />
                        </div>
                        <div class="ms-3 w-100">
                          <h6 class="mb-0">New customer is join</h6>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">Cyst Bni</p>
                            <small class="float-right font-size-12">5 days ago</small>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/03.jpg" alt="" />
                        </div>
                        <div class="ms-3 w-100">
                          <h6 class="mb-0">Two customer is left</h6>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">Cyst Bni</p>
                            <small class="float-right font-size-12">2 days ago</small>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/04.jpg" alt="" />
                        </div>
                        <div class="w-100 ms-3">
                          <h6 class="mb-0">New Mail from Fenny</h6>
                          <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">Cyst Bni</p>
                            <small class="float-right font-size-12">3 days ago</small>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a href="#" class="dropdown-toggle" id="mail-drop" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="ri-mail-line"></i>
              </a>
              <div class="sub-drop dropdown-menu" aria-labelledby="mail-drop">
                <div class="card shadow-none m-0">
                  <div class="card-header d-flex justify-content-between bg-primary">
                    <div class="header-title bg-primary">
                      <h5 class="mb-0 text-white">新訊息</h5>
                    </div>
                    <small class="badge bg-light text-dark">4</small>
                  </div>
                  <div class="card-body p-0">
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/01.jpg" alt="" />
                        </div>
                        <div class="w-100 ms-3">
                          <h6 class="mb-0">Bni Emma Watson</h6>
                          <small class="float-left font-size-12">13 Jun</small>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/02.jpg" alt="" />
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Lorem Ipsum Watson</h6>
                          <small class="float-left font-size-12">20 Apr</small>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/03.jpg" alt="" />
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Why do we use it?</h6>
                          <small class="float-left font-size-12">30 Jun</small>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/04.jpg" alt="" />
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Variations Passages</h6>
                          <small class="float-left font-size-12">12 Sep</small>
                        </div>
                      </div>
                    </a>
                    <a href="#" class="iq-sub-card">
                      <div class="d-flex align-items-center">
                        <div class="">
                          <img class="avatar-40 rounded" src="@/assets/images/user/05.jpg" alt="" />
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Lorem Ipsum generators</h6>
                          <small class="float-left font-size-12">5 Dec</small>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li> -->
            <!-- 搜尋 -->
            <!-- <div class="search-i mt-3">
              <router-link :to="{ name: 'social.search' }">
                <button type="button" class="btn btn-circle-sm mb-1 me-1">
                  <i class="fas fa-search fa-fw me-0 text-primary"></i>
                </button>
              </router-link>
            </div> -->
            <li class="nav-item dropdown">

              <a href="#" class="d-flex align-items-center dropdown-toggle" id="drop-down-arrow" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                <img :src="user.avatar" class="img-fluid rounded-circle me-1" alt="user" />
                <!-- <div class="caption">
                  <h6 class="mb-0 line-height">{{user.user_name}}</h6>
                </div> -->
              </a>
              <div class="sub-drop dropdown-menu" aria-labelledby="drop-down-arrow">
                <div class="card shadow m-0">
                  <div class="card-header bg-primary">
                    <div class="header-title">
                      <h5 class="mb-0 text-white">{{user.user_name}}</h5>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <!-- <router-link :to="{ name: 'social.profilemain' }" class="iq-sub-card iq-bg-primary-hover">
                      <div class="d-flex align-items-center">
                        <div class="rounded card-icon bg-soft-primary">
                          <i class="ri-file-user-line"></i>
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">My Profile</h6>
                          <p class="mb-0 font-size-12">
                            View personal profile details.
                          </p>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'user.profile-edit' }" class="iq-sub-card iq-bg-primary-hover">
                      <div class="d-flex align-items-center">
                        <div class="rounded card-icon bg-soft-warning">
                          <i class="ri-profile-line"></i>
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Edit Profile</h6>
                          <p class="mb-0 font-size-12">
                            Modify your personal details.
                          </p>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'user.accountsetting' }" class="iq-sub-card iq-bg-danger-hover">
                      <div class="d-flex align-items-center">
                        <div class="rounded card-icon bg-soft-info">
                          <i class="ri-account-box-line"></i>
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Account settings</h6>
                          <p class="mb-0 font-size-12">
                            Manage your account parameters.
                          </p>
                        </div>
                      </div>
                    </router-link>
                    <router-link :to="{ name: 'user.privacy-setting' }" class="iq-sub-card iq-bg-danger-hover">
                      <div class="d-flex align-items-center">
                        <div class="rounded card-icon bg-soft-danger">
                          <i class="ri-lock-line"></i>
                        </div>
                        <div class="ms-3">
                          <h6 class="mb-0">Privacy Settings</h6>
                          <p class="mb-0 font-size-12">
                            Control your privacy parameters.
                          </p>
                        </div>
                      </div>
                    </router-link> -->
                    <div class="d-inline-block w-100 text-center p-3">
                      <router-link v-on:click.enter="signOut" :to="{ name: 'auth1.sign-in' }" class="btn btn-primary iq-sign-btn" role="button">
                        {{$t('button.signOut')}}<i class="ri-login-box-line ms-2"></i></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  removeLocalStorageClient,
  setLocalStorageClientToString
} from '@/utils/assist'
import action from '@/utils/action'
import darkLoader from '@/assets/images/loader-dark.png'
import loader from '@/assets/images/loader.png'

export default {
  name: 'DefaultHeader',
  setup(props) {
    console.log(props)
    const { leftMenuClose, headShow } = action()

    return {
      leftMenuClose,
      headShow
    }
  },
  watch: {
    $route() {
      // 監控路由改變，當換頁時自動關閉左側選單
      this.leftMenuClose()
      this.SideBarMini = !this.SideBarMini
    },
    closeSidebar() {
      this.leftMenuClose()
      this.SideBarMini = !this.SideBarMini
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      darkMode: 'Setting/darkModeState',
    })
  },
  data() {
    return {
      isShow: false,
      SideBarMini: false,
      locale: '',
      dark: false
    }
  },
  props: {
    image: {
      type: String,
      default: require('@/assets/images/logo.png')
    },
    closeSidebar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sidebarmini() {
      this.SideBarMini = !this.SideBarMini
      this.checkSideBar()
    },
    checkSideBar() {
      const body = document.querySelector('body')
      if (this.SideBarMini) {
        body.classList.add('sidebar-main')
      } else {
        body.classList.remove('sidebar-main')
      }
    },
    signOut() {
      removeLocalStorageClient('user')
      removeLocalStorageClient('token')
      // this.$router.push({ name: 'auth1.sign-in' })
      location.reload()
    },
    setLocale() {
      console.log(this.$i18n.locale)
      setLocalStorageClientToString('locale', this.$i18n.locale)
      location.reload()
    },
    darkChange(mode) {
      this.dark = mode
      if (mode) {
        this.logo = darkLoader
      } else {
        this.logo = loader
      }
      // this.$emit('onLogo', this.logo)
      this.modeChange({ rtl: this.rtl, dark: this.dark })
    },
    ...mapActions({
      modeChange: 'Setting/layoutModeAction'
    })
  },
  created() {},
  mounted() {}
}
</script>
