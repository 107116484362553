<template>
  <footer class="iq-footer bg-white shadow" v-show="!isRecommendPage">
    <div class="container-fluid">
      <ul class="footer-links">
        <li>
          <router-link to="/auth/terms">服務條款</router-link>
        </li>
        <li>
          <router-link to="/auth/privacy">隱私政策</router-link>
        </li>
        <li>
          <a href="mailto:ourseewe@gmail.com">聯絡信箱</a>
        </li>
      </ul>
      <div class="row justify-content-center">
        <!-- <div class="col-lg-6">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <router-link :to="{ name: 'social.privacy' }">Privacy Policy</router-link>
            </li>
            <li class="list-inline-item">
              <router-link :to="{ name: 'social.termofservice' }">Term of Use</router-link>
            </li>
          </ul>
        </div> -->
        <div class="col-lg-6 d-flex justify-content-center copyright">
          Copyright {{ new Date().getFullYear() }}
          <a href="#">&nbsp; 私域 &nbsp; </a> All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>

  <!-- <nav class="iq-float-menu">
    <input type="checkbox" class="iq-float-menu-open" name="menu-open" id="menu-open" />
    <label class="iq-float-menu-open-button" for="menu-open">
      <span class="lines line-1"></span>
      <span class="lines line-2"></span>
      <span class="lines line-3"></span>
    </label>
    <button class="iq-float-menu-item bg-danger" @click="darkChange(!darkMode)" title="Color Mode" id="dark-mode"
      data-active="true">
      <i :class="darkMode ? 'ri-moon-clear-line' : 'ri-sun-line'" class="d-flex justify-content-center align-items-center"></i>
    </button>
  </nav> -->

  <div class="mobile-options" style="font-size: 50px">
    <template v-for="menu in menus" :key="menu">
      <div :data-bs-toggle="!menu.isLink ? 'modal' : ''" :data-bs-target="!menu.isLink ? '#modals' : ''" :class="menu.name === selectedMenu$ ? 'option active' : 'option'" @click="selectOption(menu)" style="position: relative;">
        <el-icon>
          <component :is="menu.icon" />
        </el-icon>
        <span :class="menu.name === selectedMenu$ ? 'text active' : 'text'">
          {{ menu.label }}
        </span>
        <div v-if="menu.name === 'notify'" class="unread btn-primary"><span>{{ user.notify_unread }}</span></div>
        <div v-if="menu.name === 'chat'" class="unread btn-primary"><span>{{ msgUnread }}</span></div>
      </div>
    </template>
    <div class="placeholder"></div>
  </div>
</template>
<script>
import darkLoader from '@/assets/images/loader-dark.png'
import loader from '@/assets/images/loader.png'
import { mapGetters, mapActions } from 'vuex'
import { computed } from 'vue'
import { useStore } from 'vuex'
import {
  House,
  Bell,
  ChatDotRound,
  User,
  CirclePlusFilled
} from '@element-plus/icons-vue'
export default {
  name: 'DefaultFooter',
  components: {
    House,
    Bell,
    ChatDotRound,
    User,
    CirclePlusFilled
  },
  setup() {
    const store = useStore()
    const selectedMenu$ = computed(() => store.state.Setting.selectedMenu)
    return {
      selectedMenu$
    }
  },
  data() {
    return {
      rtl: false,
      menus: [
        {
          name: 'index',
          label: this.$t('首頁'),
          icon: House,
          to: '/',
          isLink: true
        },
        {
          name: 'notify',
          label: this.$t('通知'),
          icon: Bell,
          to: '/notification',
          isLink: true
        },
        {
          name: 'add',
          label: '',
          icon: CirclePlusFilled,
          to: 'openDialog',
          isLink: false
        },
        {
          name: 'chat',
          label: this.$t('私訊'),
          icon: ChatDotRound,
          to: '/app/chat',
          isLink: true
        },
        {
          name: 'user',
          label: this.$t('個人'),
          icon: User,
          to: '/profile',
          isLink: true
        }
      ],
      dark: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      darkMode: 'Setting/darkModeState',
      msgUnread: 'User/unReadNumState'
    }),
    isRecommendPage() {
      return this.$route.name === 'socialRecommend.list'
    }
  },
  methods: {
    selectOption(menu) {
      this.$store.dispatch('Setting/selectedMenuAction', menu.name)
      if (menu.isLink) {
        this.$router.push({ path: menu.to })
      }
    },
    // changeColor(code) {
    //   document.documentElement.style.setProperty('--iq-primary', code.primary)
    //   document.documentElement.style.setProperty(
    //     '--iq-primary-light',
    //     code.primaryLight
    //   )
    //   document.documentElement.style.setProperty(
    //     '--iq-primary-dark',
    //     code.primaryDark
    //   )
    // },
    reset() {
      this.changeColor({
        primary: '#827af3',
        primaryLight: '#b47af3',
        bodyBgLight: '#efeefd',
        bodyBgDark: '#1d203f'
      })
      this.themeMode(false)
      this.rtlChange(false)
    },
    rtlChange(mode) {
      this.rtl = mode
      this.modeChange({ rtl: this.rtl, dark: this.dark })
    },
    darkChange(mode) {
      this.dark = mode
      if (mode) {
        this.logo = darkLoader
      } else {
        this.logo = loader
      }
      // this.$emit('onLogo', this.logo)
      this.modeChange({ rtl: this.rtl, dark: this.dark })
    },
    ...mapActions({
      modeChange: 'Setting/layoutModeAction'
    })
  }
}
</script>

<style scoped>
.unread {
  position: absolute;
  border-radius: 50%;
  min-width: 15px;
  min-height: 15px;
  font-size: 10px;
  left: 40px;
  top: 0;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  display: inline-block;
}
.unread:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.unread span {
  display: inline-block;
  vertical-align: middle;
}
</style>
